import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Menu, X } from "lucide-react";
import { SIGN_IN_REDIRECT_URL_QUERY_PARAM_NAME } from "@/lib/constants";
import { UserButton } from "astro-clerk-auth/client/react";

export default function Header(props: {
  signedIn: boolean;
  isUserDesignPartner: boolean;
  currentPagePathname: string;
  logo?: JSX.Element;
  feedback?: JSX.Element;
}) {
  const navigation = [
    { name: "🔒 Semantic Search", href: "/semantic" },
    { name: "Search Guide", href: "/semantic/guide" },
    // include design partners link if the user is a design partner
    ...(props.isUserDesignPartner
      ? [{ name: "🔒 Design Partners", href: "/design-partners" }]
      : []),
    { name: "Contact", href: "mailto:greg@hotseatai.com" },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const signInUrl = `/sign-in?${SIGN_IN_REDIRECT_URL_QUERY_PARAM_NAME}=${encodeURIComponent(
    props.currentPagePathname
  )}`;
  const signedIn = props.signedIn;

  const logoAndText = (
    <a className="container flex flex-row justify-start items-center px-0 gap-4" href="/">
      {props.logo}
      <div className="flex flex-col">
        <h1 className="text-xl font-bold leading-7 text-primary sm:text-2xl">Hotseat&nbsp;AI</h1>
        <h3 className="text-gray-600 font-light text-xs tracking-tighter italic">
          Legal answers at warp-speed
        </h3>
      </div>
    </a>
  );

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">{logoAndText}</div>
        <div className="hidden md:flex md:gap-x-6 text-sm font-medium text-slate-700">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="inline-block rounded-lg px-2 py-1 hover:bg-slate-100 hover:text-slate-900"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="flex gap-4 lg:hidden">
          {signedIn && <UserButton />}
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Menu className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {signedIn && <div className="mr-10">{props.feedback}</div>}
          {signedIn ? (
            <UserButton />
          ) : (
            <a href={signInUrl} className="text-sm font-semibold leading-6 text-gray-900">
              Sign in <span aria-hidden="true">&rarr;</span>
            </a>
          )}
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            {logoAndText}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <X className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              {!signedIn && (
                <div className="py-6">
                  <a
                    href={signInUrl}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Sign in
                  </a>
                </div>
              )}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
