export const SIGN_IN_REDIRECT_URL_QUERY_PARAM_NAME = "redirect_url";
export const SIGN_IN_REDIRECT_ALLOWED_HOSTS = ["buy.stripe.com"];
export const GDPR_PDF_COLLECTION_ID = "srchpdfcol_2hbKCnEx5rXrc0ffIst1rZWQllL";
export const MiCA_PDF_COLLECTION_ID = "srchpdfcol_2hep5pv1TJD3nOjaXUQGDqhZnLx";
export const DORA_PDF_COLLECTION_ID = "srchpdfcol_2hmNWaREHtQ8kvSN8oLJF22mmCK";
export const CYBERSECURITY_PDF_COLLECTION_ID = "srchpdfcol_2iUhADghP08GRPN2yx4j8gG3Neh";
export const FINANCE_TAXONOMY_REGULATION = "srchpdfcol_2iVuRyEcS6GEvPRvD5ABOoVrTHd";
export const FINANCE_PROSPECTUS_DIRECTIVE = "srchpdfcol_2iVuRvCDGRnRTMBpSqB3XpepLSH";
export const FINANCE_MARKETS_IN_FINANCIAL_INSTRUMENTS_DIRECTIVE_MIFID = "srchpdfcol_2iVuRwlb2isHJB3mGaNYP2sTnEx";
export const FINANCE_TRANSPARENCY_DIRECTIVE = "srchpdfcol_2iVuRwLkZ3WqtiPi5h2sJtj7tkk";
export const FINANCE_AUDIT_DIRECTIVE = "srchpdfcol_2iVuRvrkJbFwD0RnoS73TAfdLKe";
export const FINANCE_UNDERTAKINGS_FOR_COLLECTIVE_INVESTMENT_IN_TRANSFERABLE_SECURITIES_DIRECTIVE_UCITS = "srchpdfcol_2iVuRrEPm7uUHGMzPnlWRTJ6zIV";
export const FINANCE_CREDIT_RATING_AGENCIES_REGULATION_CRA = "srchpdfcol_2iVuRy2sgvuileWCGpF6aYO9MnS";
export const FINANCE_SHORT_SELLING_REGULATION = "srchpdfcol_2iVuRvvrtPMCj9ayupDfawZO9HJ";
export const FINANCE_OTC_DERIVATIVES_CENTRAL_COUNTERPARTIES_AND_TRADE_REPOSITORIES_REGULATION_EMIR = "srchpdfcol_2iVuRudxq1ZkqsBqcLJSamZNc6P";
export const FINANCE_ALTERNATIVE_INVESTMENT_FUND_MANAGERS_DIRECTIVE_AIFMD = "srchpdfcol_2iVuRvBuFMk1Q9nuBgNAeqZJIOX";
export const FINANCE_BANKING_PRUDENTIAL_REQUIREMENTS_REGULATION_CRR = "srchpdfcol_2iVuRuQeMj9BzxqF0NniWgACJOz";
export const FINANCE_BANKING_PRUDENTIAL_REQUIREMENTS_DIRECTIVE_CRD_IV = "srchpdfcol_2iVuRvjGGhsltn1d3ybMUT91KnS";
export const FINANCE_EUROPEAN_VENTURE_CAPITAL_FUNDS_REGULATION_EUVECA = "srchpdfcol_2iVuRsJiJfF8KLCM10v5GgblzmJ";
export const FINANCE_EUROPEAN_SOCIAL_ENTREPRENEURSHIP_FUNDS_REGULATION_EUSEF = "srchpdfcol_2iVuRxDNgeiaC3v4Zo4EQnedFxk";
export const FINANCE_OCCUPATIONAL_PENSION_FUNDS_DIRECTIVE_IORP = "srchpdfcol_2iVuRvfsWuwaUKNklGZ7EMV3wKX";
export const FINANCE_SOLVENCY_II = "srchpdfcol_2iVuRyaHDZQLs9Yk5p48Iipfozh";
export const FINANCE_MARKET_ABUSE_REGULATION_MAR = "srchpdfcol_2iVuRtetALNzbdnAUbK1VrOROZv";
export const FINANCE_SINGLE_RESOLUTION_MECHANISM_REGULATION_SRM = "srchpdfcol_2iVuRy36PgQjtwePBQgQ8dkXMrz";
export const FINANCE_MARKETS_IN_FINANCIAL_INSTRUMENTS_DIRECTIVE_II_MIFID_II = "srchpdfcol_2iVuRriAORSyr0aZkuGxxuc9Gu4";
export const FINANCE_BANK_RECOVERY_AND_RESOLUTION_DIRECTIVE_BRRD = "srchpdfcol_2iVuRswYDMbyVDOzm9SUjNzQDMh";
export const FINANCE_BENCHMARKS_REGULATION = "srchpdfcol_2iVuRyWn7h9t5OnifhxlYZu2tgq";
export const FINANCE_ANTI_MONEY_LAUNDERING_AND_TERRORIST_FINANCING_DIRECTIVE_AMLD_IV = "srchpdfcol_2iVuRv6iEFl59ju9b8p09wpWBHS";
export const FINANCE_PACKAGED_RETAIL_AND_INSURANCE_BASED_INVESTMENT_PRODUCTS_REGULATION_PRIIPS = "srchpdfcol_2iVuRt8psUJoOWlBFmNpCtcc7sj";
export const FINANCE_MARKETS_IN_FINANCIAL_INSTRUMENTS_REGULATION_MIFIR = "srchpdfcol_2iVuRwhOSNhkswF4cTUpdqtIR4K";
export const FINANCE_CENTRAL_SECURITIES_DEPOSITORIES_REGULATION_CSDR = "srchpdfcol_2iVuRsefpLN05GHiEvL46b0Ls1s";
export const FINANCE_INSURANCE_DISTRIBUTION_DIRECTIVE_IDD = "srchpdfcol_2iVuRs0eU1VrMIyop3nAqwXGM6P";
export const FINANCE_PAYMENT_SERVICES_DIRECTIVE_PSD_2 = "srchpdfcol_2iVuRvwwDJ5HApOfq4AElJfXEgE";
export const FINANCE_PAYMENT_ACCOUNTS_DIRECTIVE_PAD = "srchpdfcol_2iVuRybnZJzIwta6nvs8FMsvnZQ";
export const FINANCE_MONEY_MARKET_FUNDS_REGULATION_MMF = "srchpdfcol_2iVuRtEn1arM671wpvpo0zXxXlU";
export const FINANCE_SECURITIES_FINANCING_TRANSACTIONS_REGULATION_SFTR = "srchpdfcol_2iVuRw7JUJ41NtCGKtF51etQVgC";
export const FINANCE_SECURITISATION_REGULATION = "srchpdfcol_2iVuRyBzpJp7P7JcJTJlVPyApmO";
export const FINANCE_PROSPECTUS_REGULATION = "srchpdfcol_2iVuRx2JWIRl7LPIAQzhDY0ZyNL";
export const FINANCE_EU_CLIMATE_TRANSITION_BENCHMARKS_REGULATION = "srchpdfcol_2iVuRxUP9VDcCEkEciiiPyq1SOb";
export const FINANCE_PAN_EUROPEAN_PERSONAL_PENSION_PRODUCT_REGULATION_PEPP = "srchpdfcol_2iVuRsOmQVyNPaahZdVksJ4MPPJ";
export const FINANCE_INVESTMENT_FIRMS_REGULATION_IFR = "srchpdfcol_2iVuRsAoiZXSqWIMrsF8TqxsrU9";
export const FINANCE_INVESTMENT_FIRMS_DIRECTIVE_IFD = "srchpdfcol_2iVuRxbBcmsarvWHp4r19lv27ID";
export const FINANCE_ACCOUNTING_DIRECTIVE = "srchpdfcol_2iVuRxNCFy0IYwMVtejRcrVQ5mX";
export const FINANCE_CCP_RECOVERY_AND_RESOLUTION_REGULATION = "srchpdfcol_2iVuRxHliOIkvzahb6Z8J9Diz0Z";
export const FINANCE_INTERNATIONAL_ACCOUNTING_STANDARDS_REGULATION_IAS_REGULATION = "srchpdfcol_2iVuRtpFiWNcwzgFUXLjslFq8hZ";
export const FINANCE_REGULATIONS_AND_DIRECTIVES = "srchpdfcol_2iVuRuvMOCv3dAAiIejCU0zBcbO";

interface SupportedPdfCollection {
  id: string;
  name: string;
}

export const SUPPORTED_PDF_COLLECTIONS: SupportedPdfCollection[] = [
  { id: GDPR_PDF_COLLECTION_ID, name: "GDPR" },
  { id: MiCA_PDF_COLLECTION_ID, name: "MiCA" },
  { id: DORA_PDF_COLLECTION_ID, name: "DORA" },
  { id: CYBERSECURITY_PDF_COLLECTION_ID, name: "Cybersecurity" },
  { id: FINANCE_TAXONOMY_REGULATION, name: "Finance: Taxonomy Regulation" },
  { id: FINANCE_PROSPECTUS_DIRECTIVE, name: "Finance: Prospectus Directive" },
  { id: FINANCE_MARKETS_IN_FINANCIAL_INSTRUMENTS_DIRECTIVE_MIFID, name: "Finance: Markets in Financial Instruments Directive" },
  { id: FINANCE_TRANSPARENCY_DIRECTIVE, name: "Finance: Transparency Directive" },
  { id: FINANCE_AUDIT_DIRECTIVE, name: "Finance: Audit Directive" },
  { id: FINANCE_UNDERTAKINGS_FOR_COLLECTIVE_INVESTMENT_IN_TRANSFERABLE_SECURITIES_DIRECTIVE_UCITS, name: "Finance: Undertakings for Collective Investment in Transferable Securities Directive" },
  { id: FINANCE_CREDIT_RATING_AGENCIES_REGULATION_CRA, name: "Finance: Credit Rating Agencies Regulation" },
  { id: FINANCE_SHORT_SELLING_REGULATION, name: "Finance: Short Selling Regulation" },
  { id: FINANCE_OTC_DERIVATIVES_CENTRAL_COUNTERPARTIES_AND_TRADE_REPOSITORIES_REGULATION_EMIR, name: "Finance: OTC Derivatives, Central Counterparties and Trade Repositories Regulation" },
  { id: FINANCE_ALTERNATIVE_INVESTMENT_FUND_MANAGERS_DIRECTIVE_AIFMD, name: "Finance: Alternative Investment Fund Managers Directive" },
  { id: FINANCE_BANKING_PRUDENTIAL_REQUIREMENTS_REGULATION_CRR, name: "Finance: Banking Prudential Requirements Regulation" },
  { id: FINANCE_BANKING_PRUDENTIAL_REQUIREMENTS_DIRECTIVE_CRD_IV, name: "Finance: Banking Prudential Requirements Directive" },
  { id: FINANCE_EUROPEAN_VENTURE_CAPITAL_FUNDS_REGULATION_EUVECA, name: "Finance: European Venture Capital Funds Regulation" },
  { id: FINANCE_EUROPEAN_SOCIAL_ENTREPRENEURSHIP_FUNDS_REGULATION_EUSEF, name: "Finance: European Social Entrepreneurship Funds Regulation" },
  { id: FINANCE_OCCUPATIONAL_PENSION_FUNDS_DIRECTIVE_IORP, name: "Finance: Occupational Pension Funds Directive" },
  { id: FINANCE_SOLVENCY_II, name: "Finance: Solvency II" },
  { id: FINANCE_MARKET_ABUSE_REGULATION_MAR, name: "Finance: Market Abuse Regulation" },
  { id: FINANCE_SINGLE_RESOLUTION_MECHANISM_REGULATION_SRM, name: "Finance: Single Resolution Mechanism Regulation" },
  { id: FINANCE_MARKETS_IN_FINANCIAL_INSTRUMENTS_DIRECTIVE_II_MIFID_II, name: "Finance: Markets in Financial Instruments Directive II" },
  { id: FINANCE_BANK_RECOVERY_AND_RESOLUTION_DIRECTIVE_BRRD, name: "Finance: Bank Recovery and Resolution Directive" },
  { id: FINANCE_BENCHMARKS_REGULATION, name: "Finance: Benchmarks Regulation" },
  { id: FINANCE_ANTI_MONEY_LAUNDERING_AND_TERRORIST_FINANCING_DIRECTIVE_AMLD_IV, name: "Finance: Anti-Money Laundering and Terrorist Financing Directive" },
  { id: FINANCE_PACKAGED_RETAIL_AND_INSURANCE_BASED_INVESTMENT_PRODUCTS_REGULATION_PRIIPS, name: "Finance: Packaged Retail and Insurance Based Investment Products Regulation" },
  { id: FINANCE_MARKETS_IN_FINANCIAL_INSTRUMENTS_REGULATION_MIFIR, name: "Finance: Markets in Financial Instruments Regulation" },
  { id: FINANCE_CENTRAL_SECURITIES_DEPOSITORIES_REGULATION_CSDR, name: "Finance: Central Securities Depositories Regulation" },
  { id: FINANCE_INSURANCE_DISTRIBUTION_DIRECTIVE_IDD, name: "Finance: Insurance Distribution Directive" },
  { id: FINANCE_PAYMENT_SERVICES_DIRECTIVE_PSD_2, name: "Finance: Payment Services Directive" },
  { id: FINANCE_PAYMENT_ACCOUNTS_DIRECTIVE_PAD, name: "Finance: Payment Accounts Directive" },
  { id: FINANCE_MONEY_MARKET_FUNDS_REGULATION_MMF, name: "Finance: Money Market Funds Regulation" },
  { id: FINANCE_SECURITIES_FINANCING_TRANSACTIONS_REGULATION_SFTR, name: "Finance: Securities Financing Transactions Regulation" },
  { id: FINANCE_SECURITISATION_REGULATION, name: "Finance: Securitisation Regulation" },
  { id: FINANCE_PROSPECTUS_REGULATION, name: "Finance: Prospectus Regulation" },
  { id: FINANCE_EU_CLIMATE_TRANSITION_BENCHMARKS_REGULATION, name: "Finance: EU Climate Transition Benchmarks Regulation" },
  { id: FINANCE_PAN_EUROPEAN_PERSONAL_PENSION_PRODUCT_REGULATION_PEPP, name: "Finance: Pan-European Personal Pension Product Regulation" },
  { id: FINANCE_INVESTMENT_FIRMS_REGULATION_IFR, name: "Finance: Investment Firms Regulation" },
  { id: FINANCE_INVESTMENT_FIRMS_DIRECTIVE_IFD, name: "Finance: Investment Firms Directive" },
  { id: FINANCE_ACCOUNTING_DIRECTIVE, name: "Finance: Accounting Directive" },
  { id: FINANCE_CCP_RECOVERY_AND_RESOLUTION_REGULATION, name: "Finance: CCP Recovery and Resolution Regulation" },
  { id: FINANCE_INTERNATIONAL_ACCOUNTING_STANDARDS_REGULATION_IAS_REGULATION, name: "Finance: International Accounting Standards Regulation" },
  { id: FINANCE_REGULATIONS_AND_DIRECTIVES, name: "Finance: Regulations and Directives" },
];

export const ALL_HOTSEAT_DOCUMENTS_MAGIC_VALUE = "allHotseat";
